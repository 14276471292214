import React from "react"
import cx from "classnames"
import toReact from "svelte-adapter/react"
import SignUpComponent from "webkit/ui/LoginPrompt/SignUp.svelte"
import Layout from "../../components/layout"
import styles from "./index.module.scss"

const SignUp = toReact(SignUpComponent, {}, "div")

export default () => (
  <Layout classes={{ main: cx(styles.wrapper, "row hv-center") }}>
    <SignUp title="Welcome to SanAPI" />
  </Layout>
)
